import BaseBean from "@/utils/BaseBean";
export interface IUserListDataObj{
    utilInst:UserListUtil
    refMap:Map<string,any>
    pageListRef:any
    corpTreeData:Array<any>
    deptTreeData:Array<any>
    personData:Array<any>
    pageList:any
    importGuidParams:any
}
export default class UserListUtil extends BaseBean{
    public dataObj:IUserListDataObj

    constructor(proxy:any,dataObj:IUserListDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    public async requestDeptData(corpId:string):Promise<any>{
        this.dataObj.deptTreeData = await this.utils.Api.deptTreeData({
            corpId: corpId
        });
    }
    public async requestPersonData(corpId:string,deptId:string):Promise<any>{
        this.dataObj.personData = await this.utils.Api.getPersons({
            corpId: corpId,
            deptId:deptId
        });
        this.dataObj.pageList.queryParam.personId='';
    }
    public async corpChange(selItem:string):Promise<any>{
        await this.requestDeptData(selItem);
        await this.requestPersonData(selItem,'');
    }
    public async deptChange(selItem:string):Promise<any>{
        await this.requestPersonData(this.dataObj.pageList.queryParam.corpId,selItem);
    }
}